import axios from "axios";

export const getUser = async ({ requestUser }) => {
    try {
      const res = await axios.post(
        `https://dm-fast-api-cms-gis.azurewebsites.net/analytics_login/`,
        { username:requestUser.username, password: requestUser.password }
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  };